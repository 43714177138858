import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store'
import md5 from 'md5'
import { profileActions } from './store/profileReducer'
import { removeNulls } from './utils'

// const API_URL = 'https://carfeed.splinex.com/api'
const API_URL = 'https://feeds.dupontregistry.tools/api'
const ELASTICSEARCH_URL = 'http://0.0.0.0:9200'
const PASSWORD_SALT = 'f9a4751a5465a2a964a45ecd7fbe6786'

type WithPagination<T> = {
  pagination: Pagination
  objects: T[]
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).profile.token
    if (token) headers.set('Authorization', token)
    return headers
  },
})

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Exceptions',
    'ReplacementRules',
    'ExclusionRules',
    'PriceType',
    'WorkerTasks',
    'Makes',
    'Models',
    'VinMap',
    'Dealers',
  ],
  endpoints: (builder) => ({
    // User
    login: builder.mutation<User | { error: string }, { email: string; password: string }>({
      query: (args) => {
        return {
          url: '/login',
          method: 'POST',
          body: {
            ...args,
            password: md5(args.password + PASSWORD_SALT),
          },
        }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            if (!('error' in r.data)) {
              dispatch(profileActions.setUser(r.data))
            }
          })
          .catch(() => {
            dispatch(profileActions.updateUserInfo({ confirmed: false }))
          })
      },
    }),
    register: builder.mutation<
      User | { error: string },
      { email: string; password: string; first_name: string; last_name: string }
    >({
      query: (args) => {
        return {
          url: '/register',
          method: 'POST',
          body: {
            ...args,
            password: md5(args.password + PASSWORD_SALT),
          },
        }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled.then((r) => {
          if (!('error' in r.data)) {
            dispatch(profileActions.setUser(r.data))
          }
        })
      },
    }),
    getUser: builder.query<Omit<User, 'token'>, void>({
      query: () => '/user',
      async onQueryStarted(arg: void, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then((r) => {
            dispatch(profileActions.updateUserInfo(r.data))
          })
          .catch(() => dispatch(profileActions.logout()))
      },
    }),

    // Makes
    getMakes: builder.query<WithPagination<Make>, MakeRequestParams | void>({
      query: (params: MakeRequestParams = {}) => {
        return {
          url: '/makes',
          params,
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'Makes' }] : []),
    }),
    createMake: builder.mutation<Make, MakePostBody>({
      query: (body) => {
        return {
          url: '/makes',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'Makes' }],
    }),
    patchMake: builder.mutation<Make, Make>({
      query: ({ id, ...body }) => {
        return {
          url: '/make/' + id,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'Makes', id: 'All' },
          ])) {
            if (endpointName !== 'getMakes') continue
            dispatch(
              api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                draft.objects = draft.objects.map((e) => {
                  if (e.id === resp.data.id) return resp.data
                  else return e
                })
              }),
            )
          }
        })
      },
    }),
    deleteMake: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: '/make/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'Makes' }],
    }),

    // Models
    getModels: builder.query<WithPagination<Model>, ModelRequestParams | void>({
      query: (params: ModelRequestParams = {}) => {
        return {
          url: '/models',
          params,
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'Models' }] : []),
    }),
    createModel: builder.mutation<Model, ModelPostBody>({
      query: (body) => {
        return {
          url: '/models',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'Models' }],
    }),
    patchModel: builder.mutation<Model, Omit<Model, 'make_name'>>({
      query: ({ id, ...body }) => {
        return {
          url: '/model/' + id,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'Models', id: 'All' },
          ])) {
            if (endpointName !== 'getModels') continue
            dispatch(
              api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                draft.objects = draft.objects.map((e) => {
                  if (e.id === resp.data.id) return resp.data
                  else return e
                })
              }),
            )
          }
        })
      },
    }),
    deleteModel: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: '/model/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'Models' }],
    }),

    // Exceptions
    getExceptionDates: builder.query<number[], void>({
      query: () => '/exception_list/dates',
      transformResponse: (response: number[]) => {
        return response.sort((a, b) => (a > b ? -1 : 1))
      },
    }),
    getExceptions: builder.query<WithPagination<Exception>, ExceptionsRequestParams | void>({
      query: (params: ExceptionsRequestParams = {}) => {
        if (params.limit === undefined) params = { ...params, limit: 20 }
        return {
          url: '/exception_list',
          params,
        }
      },
      transformResponse: (response: WithPagination<Exception>) => {
        return {
          ...response,
          objects: response.objects.map((e) => ({ ...e, create_at: e.create_at * 1000 })),
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'Exceptions' }] : []),
    }),
    deleteException: builder.mutation<number[], number>({
      query: (id) => {
        return {
          url: '/exception_list/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'Exceptions' }],
    }),

    // Replacement Rules
    getReplacementRules: builder.query<WithPagination<ReplacementRule>, ReplacementRulesRequestParams | void>({
      query: (params: ReplacementRulesRequestParams = {}) => {
        if (params.limit === undefined) params = { ...params, limit: 20 }
        return {
          url: '/replacement_rule',
          params: { order_by: 'id', ...params },
        }
      },
      transformResponse: (response: WithPagination<ReplacementRule>) => {
        return {
          ...response,
          objects: response.objects.map((r) => removeNulls(r)),
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'ReplacementRules' }] : []),
    }),
    createReplacementRules: builder.mutation<ReplacementRule, ReplacementRulesPostBody>({
      query: (body) => {
        return {
          url: '/replacement_rule',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'ReplacementRules' }],
    }),
    patchReplacementRules: builder.mutation<ReplacementRule, ReplacementRulesUpdateBody>({
      query: ({ id, ...body }) => {
        return {
          url: '/replacement_rule/' + id,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'ReplacementRules', id: 'All' },
          ])) {
            if (endpointName !== 'getReplacementRules') continue
            dispatch(
              api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                draft.objects = draft.objects.map((e) => {
                  if (e.id === resp.data.id) return resp.data
                  else return e
                })
              }),
            )
          }
        })
      },
    }),
    deleteReplacementRule: builder.mutation<void, number>({
      query(id) {
        return {
          url: '/replacement_rule/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'ReplacementRules' }],
    }),

    // Exclusion Rules
    getExclusionRules: builder.query<WithPagination<ExclusionRule>, ExclusionRuleParams>({
      query: (params: ExclusionRuleParams = {}) => {
        params = { order_by: 'id', ...params }
        return {
          url: '/exclusion_rule',
          params: params,
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'ExclusionRules' }] : []),
    }),
    createExclusionRule: builder.mutation<{ error: string } | ExclusionRule, ExclusionRuleCreate>({
      query: (body) => {
        return {
          url: '/exclusion_rule',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'ExclusionRules' }],
    }),
    deleteExclusionRule: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `/exclusion_rule/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'ExclusionRules' }],
    }),
    patchExclusionRule: builder.mutation<
      { error: string } | ExclusionRule,
      { id: number; body: Partial<ExclusionRuleCreate> }
    >({
      query: (args) => {
        return {
          url: '/exclusion_rule/' + args.id,
          method: 'PATCH',
          body: args.body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'ReplacementRules' }],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          const updatedRule = resp.data
          if (!('error' in updatedRule)) {
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'ExclusionRules', id: 'All' },
            ])) {
              if (endpointName !== 'getExclusionRules') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                  draft.objects = draft.objects.map((e) => {
                    if (e.id === updatedRule.id) return updatedRule
                    else return e
                  })
                }),
              )
            }
          }
        })
      },
    }),

    // Price type
    getPriceType: builder.query<PriceType[], any | void>({
      query: (params) => {
        return {
          url: '/price_type',
          params,
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'PriceType' }] : []),
    }),
    createPriceType: builder.mutation<PriceType, Omit<PriceType, 'id'>>({
      query: (body) => {
        return {
          url: '/price_type',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'PriceType' }],
    }),
    patchPriceType: builder.mutation<PriceType, { id: number; body: Partial<Omit<PriceType, 'id'>> }>({
      query: (args) => {
        return {
          url: '/price_type/' + args.id,
          method: 'PATCH',
          body: args.body,
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          const updatedPriceType = resp.data
          for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'PriceType', id: 'All' },
          ])) {
            if (endpointName !== 'getPriceType') continue
            dispatch(
              api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                const oldPriceType = draft.find((p) => p.id === updatedPriceType.id)
                if (oldPriceType !== undefined) Object.assign(oldPriceType, updatedPriceType)
              }),
            )
          }
        })
      },
    }),
    deletePriceType: builder.mutation<void, { id: number }>({
      query: (body) => {
        return {
          url: `/price_type/${body.id}`,
          method: 'DELETE',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'PriceType' }],
    }),

    // Worker tasks
    getWorkerTasks: builder.query<WithPagination<WorkerTask>, (PaginationParams & { order_by?: string }) | void>({
      query: (params: PaginationParams & { order_by?: string } = {}) => {
        return {
          url: '/worker_task',
          params,
        }
      },
      transformResponse: (response: WithPagination<WorkerTask>) => {
        return {
          ...response,
          objects: response.objects.map((e) => ({
            ...e,
            create_at: e.create_at * 1000,
            update_at: e.create_at * 1000,
          })),
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'WorkerTasks' }] : []),
    }),
    createWorkerTask: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/worker_task',
          method: 'POST',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'WorkerTasks' }],
    }),

    // VinMap
    getVinMaps: builder.query<WithPagination<VinMap>, VinMapRequestParams | void>({
      query: (params: PaginationParams = {}) => {
        return {
          url: '/vin_map',
          params,
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'VinMap' }] : []),
    }),
    createVinMap: builder.mutation<VinMap, Omit<VinMap, 'id'>>({
      query: (body) => {
        return {
          url: '/vin_map',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'All', type: 'PriceType' }],
    }),
    patchVinMap: builder.mutation<VinMap, VinMap>({
      query: ({ id, ...body }) => {
        return {
          url: '/vin_map/' + id,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          const updatedVinMap = resp.data
          for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'VinMap', id: 'All' },
          ])) {
            if (endpointName !== 'getVinMaps') continue
            dispatch(
              api.util.updateQueryData(endpointName, originalArgs, (draft) => {
                const oldVinMap = draft.objects.find((p) => p.id === updatedVinMap.id)
                if (oldVinMap !== undefined) Object.assign(oldVinMap, updatedVinMap)
              }),
            )
          }
        })
      },
    }),
    deleteVinMap: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: '/vin_map/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'All', type: 'VinMap' }],
    }),

    // Dealers
    getDealers: builder.query<WithPagination<Dealer>, void>({
      query: () => {
        return {
          url: '/dealer',
          params: { all: true, order_by: 'name' },
        }
      },
      providesTags: (resp) => (resp ? [{ id: 'All', type: 'Dealers' }] : []),
    }),

    // Logs
    getCarLogs: builder.query<Record<any, any>[], any>({
      query: (params: ExceptionsRequestParams = {}) => {
        if (params.limit === undefined) params = { ...params }
        return {
          url: `${API_URL}/logs/`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (response: any) => response.result.map((hit: any) => hit._source),
    }),

    getLogDates: builder.query<number[], void>({
      query: () => `${API_URL}/logs/dates`,
      transformResponse: (response: number[]) => {
        return response.sort((a, b) => (a > b ? -1 : 1))
      },
    }),
  }),
})

export default api
